<template>
    <section class="featured-discounts-event-carrousel">
        <div class="featured-discounts-event-carrousel__head">
            <h2
                class="featured-discounts-event-carrousel__head-title"
                :class="{
                    'text-gray-800': $route.name === 'eventos-slug',
                    'text-white': $route.name === 'cobee',
                }"
            >
                {{ title }}
            </h2>
        </div>

        <WidgetSlider v-if="info.length" class="h-[238px] sm:h-[238px] lg:h-[238px]">
            <WidgetSliderItem v-for="(discount, key) in info" :key="key">
                <CardFeaturedDiscount
                    :info="discount"
                    :is-for-slider="true"
                    :lazy-image="mobile ? key > 1 : key > 2"
                />
            </WidgetSliderItem>
        </WidgetSlider>

        <MiscNoResults
            v-else
            mode="discounts"
            :title="$lang.components.CardSliderEventFeaturedDiscounts.discount_not_found"
        />
    </section>
</template>

<script lang="ts">
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'CardSliderFeaturedDiscounts',
    props: {
        title: {
            type: String,
            default: 'Descuentos destacados',
        },
        info: {
            type: Array as PropType<Models.Discount[]>,
            required: true,
        },
    },
    data() {
        return {
            skeletons: [1, 2, 3],
        }
    },
    computed: {
        mobile() {
            return this.$bp?.mb
        },
    },
})
</script>

<style lang="postcss" scoped>
.featured-discounts-event-carrousel {
    @apply px-2 text-lg xs:px-0;
    &__slider {
        @apply container h-[238px];
    }
    &__head {
        @apply mb-3 flex space-x-6;
        &-title {
            @apply text-xl font-semibold;
        }
    }
    .offers_offer {
        @apply flex h-[7.3rem] w-72 rounded-2xl border bg-white md:w-80;
    }
}
</style>
